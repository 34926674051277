import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "./store/auth/authRedux";
import {
    foldersUsersFilterName, foldersUsersFilterReducer,
    usersDeleteName, usersDeleteReducer,
    usersEditName,
    usersEditReducer, UsersForFoldersEditName, UsersForFoldersEditReducer,
    usersListName,
    usersListReducer
} from "./store/users";

import {
    citiesDeleteName,
    citiesDeleteReducer,
    citiesEditName,
    citiesEditReducer,
    citiesListName,
    citiesListReducer
} from "./store/cities";
import {
    foldersDeleteName,
    foldersDeleteReducer, foldersDetailsName, foldersDetailsReducer,
    foldersEditName,
    foldersEditReducer,
    foldersListName,
    foldersListReducer, foldersUpdatesEditName, foldersUpdatesEditReducer
} from "./store/folders";

import {
    routesDeleteName, routesDeleteReducer,
    routesEditName,
    routesEditReducer,
    routesForAppName, routesForAppReducer, routesForRolesEditName, routesForRolesEditReducer,
    routesListName,
    routesListReducer
} from "./store/routes";

import {
    rolesDeleteName,
    rolesDeleteReducer,
    rolesEditName,
    rolesEditReducer, rolesListForUsersEditActions,
    rolesListName,
    rolesListReducer, rolesListUsersEditName, rolesListUsersEditReducer
} from "./store/roles";

import {logsListForFoldersDetailName, logsListForFoldersDetailReducer} from "./store/logs";
import {passwordEditName, passwordEditReducer, profileEditName, profileEditReducer} from "./store/auth";


export const rootReducer = combineReducers({
    auth: auth.reducer,
    //auth & profile
    [profileEditName]: profileEditReducer,
    [passwordEditName]: passwordEditReducer,
    //users
    [usersListName]: usersListReducer,
    [usersEditName]: usersEditReducer,
    [usersDeleteName]: usersDeleteReducer,
    [foldersUsersFilterName]: foldersUsersFilterReducer,
    [UsersForFoldersEditName]: UsersForFoldersEditReducer,
    // cities
    [citiesListName]: citiesListReducer,
    [citiesEditName]: citiesEditReducer,
    [citiesDeleteName]: citiesDeleteReducer,
    //
    [foldersListName]: foldersListReducer,
    [foldersEditName]: foldersEditReducer,
    [foldersDeleteName]: foldersDeleteReducer,
    [foldersDetailsName]: foldersDetailsReducer,
    [foldersUpdatesEditName]: foldersUpdatesEditReducer,
    //
    [rolesListName]: rolesListReducer,
    [rolesEditName]: rolesEditReducer,
    [rolesDeleteName]: rolesDeleteReducer,
    [rolesListUsersEditName]: rolesListUsersEditReducer,
    //
    [logsListForFoldersDetailName]: logsListForFoldersDetailReducer,
    //
    [routesListName]: routesListReducer,
    [routesEditName]: routesEditReducer,
    [routesDeleteName]: routesDeleteReducer,
    [routesForAppName]: routesForAppReducer,
    [routesForRolesEditName]: routesForRolesEditReducer,

});

export function* rootSaga() {
    yield all([auth.saga()]);
}
