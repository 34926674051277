import {getSlice, useCustomSelector} from "../../helpers";
import * as API from "../api";
import {ROUTES} from "../../../../app/Routes";
import {MODULES} from "../../../../_ae/helpers/RoutingHelpers";

const {actions, name, reducer} = getSlice({
  name: `${MODULES.ROUTES}.listForApp`,
  data: [],
  // metadata: new AEMetadata(
  //   {
  //     perPage: 1000
  //   },
  //   {field: 'id'},
  //   {},
  //   {"*": [], 'assignTo': {'*': [], 'location': {'*': []}}}
  // )
})

const {startCall, endCall, fetched, catchError, reset} = actions;

const resetDispatcher = () => dispatch => {
  dispatch(reset());
}

const fetchDispatcher = metadata => dispatch => {
  dispatch(startCall());

  // return getAuthUserRoutes().then(r=>{
  //   dispatch(fetched(r));
  //
  //   return r;
  //
  //   // const routes = r.data._data;
  //   // const roles = r.data._data;
  //   // let routes = [];
  //   // roles.forEach(role=>{
  //   //   role.roleRoutes.forEach(roleRoute=>{
  //   //     routes.push(roleRoute.route)
  //   //   })
  //   // })
  //   // r.data._data = routes;
  //   // console.log(routes)
  // }).catch(response => {
  //   dispatch(catchError(response));
  //
  //   return response;
  // }).then(response=>{
  //   dispatch(endCall(response));
  // })

  return API
    .all(metadata)
    .then(response => {
      response.data._data = response.data._data.map(dbRoute=>{

        const toMerge = ROUTES.find(appRoute=>appRoute.id === dbRoute.routeId);

        // dbRoute._appRoute = {
        //   ...toMerge,
        //   roles: dbRoute.routeRoles.map(routeRole=>routeRole.role.roleId)
        // }


        return {
          ...toMerge,
          roles: dbRoute.routeRoles.map(routeRole=>routeRole.role.roleId),
          dbRoute
        }
      })
      dispatch(fetched(response));

      return response;
    })
    .catch(response => {
      dispatch(catchError(response));

      return response;
    }).then(response=>{
      dispatch(endCall(response));
    })
    ;
};

const useSelector = () => useCustomSelector(name)

export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  useSelector
}


