import {getSlice, useCustomSelector} from "../../helpers";
import {AEMetadata} from "../../../../_ae/AEPagination";
import * as API from "../api";
import {MODULES} from "../../../../_ae/helpers/RoutingHelpers";

const {actions, name, reducer} = getSlice({
    name: `${MODULES.FOLDERS}.list`,
    data: [],
    metadata: new AEMetadata(
        {perPage: 999999999},
        {field: 'reference'},
        {},
        {"*": [], 'city': {'*': []}, 'logs': {'*': [], 'assignTo': {'*': []}}}
    )
})

const {startCall, endCall, fetched, catchError, reset} = actions;

const resetDispatcher = () => dispatch => {
    dispatch(reset());
}

const fetchDispatcher = metadata => dispatch => {
    dispatch(startCall());

    return API
        .all(metadata)
        .then(response => {
            dispatch(fetched(response));

            return response;
        })
        .catch(response => {
            dispatch(catchError(response));

            return response;
        }).then(response => {
            dispatch(endCall(response));
        })
        ;
};

const useSelector = () => useCustomSelector(name)

export {
    actions,
    name,
    reducer,
    fetchDispatcher,
    resetDispatcher,
    useSelector
}


