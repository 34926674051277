export const MODULES = {
  USERS: 'users',
  FOLDERS: 'folders',
  CITIES: 'cities',
  ROUTES: 'routes',
  ROLES: 'roles',
  LOGS: 'logs',
  PROFILE: 'profile',
  AUTH: 'auth'
}
export const VIEWS = {
  MENU: 'MENU',
  ACTION: 'ACTION',
  DIALOG: 'DIALOG'
}