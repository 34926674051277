import {toAbsoluteUrl} from "../_metronic/_helpers";

export const CONFIG = {
  logo: toAbsoluteUrl("/media/logos/logo-dark.png"),
  roles: {
    CO: "ROLE_CONSULTANT",
    C: "ROLE_CLIENT",
    A: "ROLE_AGENT",
    SA: "ROLE_SUPER_ADMIN"
  },
  auth: {
    login: {
      google: false
    },
    register: {
      enabled: false
    },
  }
}

//fixme: duplicated
export const ROLES = CONFIG.roles;


