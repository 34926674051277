import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {getConfig, MetronicI18nProvider} from "./_metronic/i18n";
import * as _redux from "./redux";
import axios from "axios";
import store, {persistor} from "./redux/store";
import {ROLES} from "./_ae/config";
// 3rd-party Plugins
import "../node_modules/highlight.js/styles/googlecode.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

import {HeaderProvider, MetronicLayoutProvider, MetronicSplashScreenProvider} from "./_metronic/layout";
import App from "./app/App";

_redux.setupAxios(axios, store);

const AppLoader = React.lazy(() => {
  const {rtl, role} = getConfig()
  if(role === ROLES.A) {

    return rtl ? import("./rtl") : import("./ltr")
  }

  return rtl ? import("./rtl-secondary") : import("./ltr-secondary")
});



ReactDOM.render(
  <Suspense fallback={<div/>}>
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <HeaderProvider>
          <MetronicSplashScreenProvider>
            <AppLoader />
            <App basename={process.env.PUBLIC_URL} />
          </MetronicSplashScreenProvider>
        </HeaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>
  </Suspense>
  ,
  document.getElementById("root")
);