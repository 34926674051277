// import {toAbsoluteUrl} from "../../_metronic/_helpers";


export const API_UPLOAD_PATH = `/uploads`;
export const API_DOCUMENTS_PATH = `${API_UPLOAD_PATH}/documents`;


//TODO use toAbsoluteUrl from AssetsHelpers.js
export const toAbsoluteUrl = (pathname= "") => process.env.REACT_APP_PUBLIC_URL + pathname;
export const languages = [
  {
    lang: "ar",
    name: "ARABIC",
    flag: toAbsoluteUrl("/media/svg/flags/166-morocco.svg"),
    rtl: true,

  },
  {
    lang: "en",
    name: "ENGLISH",
    flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
  },
  {
    lang: "fr",
    name: "FRENCH",
    flag: toAbsoluteUrl("/media/svg/flags/195-france.svg"),
  },
  // {
  //   lang: "es",
  //   name: "SPANISH",
  //   flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg"),
  // }
];
export const languagesKeys = languages.map(l=>l.lang)
// export const localField = (name = 'name') => `${name}_${getConfig().selectedLang}`
export const localField = (name = 'name') => `${name}`
export const getFieldNames = name => languagesKeys.map(l=>`${name}_${l}`)


export const SITE_NAME = "AB-EXPERTISE"
export const getArrayOfNumbers = length => Array.from(Array(length).keys()).map(k => k + 1)
export const EmailDomains = ['yahoo.com','hotmail.com','hotmail.fr','gmail.com']

export const AETruncate = ({text, length, useWordBoundary, className = ''} )=>{
  if (!text) return '';
  if (!length) return text;
  if (text.length <= length) return text;
  const subString = text.substr(0, length-1); // the original check

  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(" "))
    : subString) + " ...";
};

