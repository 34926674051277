import React from "react";
import {AEAutocompleteField, AEField, AEFileField} from "../../../../../_metronic/_partials/controls/forms/AEField";
// import { useLocationsForProfileEditState } from "../../../../../redux/store/locations";

//todo server validation errors
export const Form = ({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       btnRef,
}) => {
  // const {data: locations} = useLocationsForProfileEditState()

  return (
    <div className="form form-label-right">
      <div className={'row'}>
        <div className={'col-4'}>
          <AEFileField
            name="fileName"
            label={'IMAGE'}
            preview
            previewPath={'/users'}
          />
        </div>
        <div className={'col-8'}>
          <div className="form-group">
            <AEField
              name="firstName"
              label={'FIRST_NAME'}
            />
          </div>
          <div className="form-group">
            <AEField
              name="lastName"
              label={'LAST_NAME'}
            />
          </div>
          {/*<div className="form-group">*/}
          {/*  <AEAutocompleteField*/}
          {/*    name="location"*/}
          {/*    label={'LOCATION'}*/}
          {/*    options={locations}*/}
          {/*    getOptionLabel={o=>o.name}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>


      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

