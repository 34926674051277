import React, {useEffect} from "react";
import {getConfig} from "../../_metronic/i18n";
import {ROLES} from "../config";
import {shallowEqual, useSelector} from "react-redux";


/* Role checker */



export const AERole = ({roles = [], exceptionMode, children}) =>{
  // const {role} = getConfig()
  const {authUser} = useSelector( ({auth}) => ({ authUser:auth.user }), shallowEqual );

  // useEffect(()=>{}, [])
  // const roleExists = accept.filter((s,i,self)=>self.indexOf(s) === i).includes(role);
  const roleExists = roles.filter(value =>{
    return exceptionMode ?
      ! authUser.roles.includes(value) :
      authUser.roles.includes(value)
  } ).length > 0
  // console.log(accept, role, roleExists)
  if(! roleExists) return '';

  return children
}