import React from "react";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import {getConfig} from "../../i18n";
import {ROLES} from "../../../_ae/config";
//
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const config = getConfig();


const colors = {
  primary: "#f9a563",
  secondary:"#3a2588"
}

/**
 * @see https://material-ui.com/customization/themes/#theme-configuration-variables
 */

export function MaterialThemeProvider(props) {
  const { children } = props;
  const theme = createMuiTheme(
    {
      overrides: {
        MuiOutlinedInput: {
          notchedOutline: { borderColor: '#E4E6EF !important'},
        },
        MuiFormLabel:{ root:{color:"#B5B5C3 !important"}}
      },
      direction:  config.rtl ? "rtl" : "ltr",
      typography: {
        fontFamily: ["Poppins"].join(",")
      },

      palette: {
        primary: {
          main: ![ROLES.A, ROLES.SA].includes(config.role) ? colors.primary: colors.secondary
        },
        secondary: {
          main: ![ROLES.A, ROLES.SA].includes(config.role) ? colors.secondary: colors.primary,
        },
        error: {
          main: "#f018a6"
        }
      },

      /**
       * @see https://material-ui.com/customization/globals/#default-props
       */
      props: {
        // Name of the component ⚛️
        MuiButtonBase: {
          // The properties to apply
          disableRipple: false // No more ripple, on the whole application 💣!
        },

        // Set default elevation to 1 for popovers.
        MuiPopover: {
          elevation: 1
        }
      }
    }
  );

  // console.log(theme)

  return (
    <StylesProvider jss={jss} /*flip={config.rtl}*/>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>;
    </StylesProvider>
  )
}
