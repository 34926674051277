import {toAbsoluteUrl} from "../../_metronic/_helpers";
import React from "react";
import "./emyloder.css"
import {useIntl} from "react-intl";
import {CONFIG} from "../config";

export const AESpinner = (props) => {
    const {formatMessage} = useIntl();

    const m = formatMessage({id: 'LOADING'})
    return (
        <div {...props}>
            {/*<span className="pl-3 font-weight-bolder">{m}</span>*/}
            <img
                // src={CONFIG.logo}
                src={toAbsoluteUrl("/media/logos/logo-letter-5.png")}
                className="rotate max-h-15px" alt={m}
            />

        </div>

    )
}
