
import {getSlice, useCustomSelector} from "../../helpers";
import * as API from "../api";
import {MODULES} from "../../../../_ae/helpers/RoutingHelpers";

const {actions, name, reducer} = getSlice({
    name: `${MODULES.LOGS}.downloadZip`,
})

const {startCall, endCall, fetched, catchError, reset} = actions;


const resetDispatcher = () => dispatch => {
    dispatch(reset());
}

const downloadDispatcher = (id) => dispatch => {
    dispatch(startCall());

    return API.downloadZip(id)
        .then(response => {
            dispatch(fetched(response));
        })
        .catch(response => {
            dispatch(catchError(response));
        }).then(response => {
            dispatch(endCall(response));
        })
        ;
};

const useSelector = () => useCustomSelector(name)


export {
    actions,
    name,
    reducer,
    resetDispatcher,
    downloadDispatcher,
    useSelector
}
